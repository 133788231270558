<template>
  <div id="age">
    <v-container>
      <v-row>
        <v-col
          offset-md="9"
          sm="12"
        >
          <div class="language-btn-div">
            <v-icon class="icon-language ma-2">mdi-translate</v-icon><a v-on:click="setLang('es')">ES</a> | <a v-on:click="setLang('en')">EN</a> | <a v-on:click="setLang('fr')">FR</a>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          offset-md="3"
          cols="12"
          md="6"
          sm="12"
        >
          <v-img
            :src="require('@/assets/logos/logo_popol_vuh_border.png')"
            class="lighten-2"
            height="350"
            width="100%"
            contain
          >
            <v-row
              class="fill-height pa-3"
              align="end"
            >
              <v-col
                cols="12"
                md="7"
                offset="5"
              >
              </v-col>
            </v-row>
          </v-img>
          <div class="subheading text-uppercase text-center font-weight-bold age-text mt-5">
            {{ this.content.question }}
          </div>
        </v-col>
      </v-row>
      <v-row class="text-center">
        <v-col
          offset-md="3"
          md="3"
          sm="6"
        >
          <v-btn
            color="primary"
            fab
            large
            v-on:click="onNo"
            class="btn-age-text"
          >
            {{ this.content.noBtn }}
          </v-btn>
        </v-col>
        <v-col
          md="3"
          sm="6"
        >
          <v-btn
            color="tertiary"
            fab
            large
            v-on:click="onYes"
            class="btn-age-text"
          >
            {{ this.content.yesBtn }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="text-center text-uppercase white--text">
        <v-col
          cols=10
          offset="1"
        >
          {{ this.content.warning }}
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import { mapMutations } from 'vuex'
  import { languageMethods } from '../../shared'

  export default {
    name: 'Age',
    mixins: [languageMethods],
    computed: {
      content () {
        return this.$store.state.webpagecontent[0][this.$store.state.lang].landingPage
      },
    },
    methods: {
      ...mapMutations(['ofAge']),
      onNo () {
        window.location.href = 'http://alcoholinformate.org.mx/consumoresponsable/'
      },
      onYes () {
        this.ofAge()
      },
      setLang (lang) {
        this.setCurrentLanguage(lang)
      },
    },
  }
</script>

<style scoped>
  .age-text {
    font-size: 24px;
    color: #ffffff;
  }
  .btn-age-text {
    color: #3F5465 !important;
  }
  .language-btn-div {
    color: #e2eef1;
  }
  .icon-language {
    color: #e2eef1 !important;
  }
</style>
